import { useState } from 'react';
import Button from '../../common/Button';
import { Heading, Text } from '../../common/typography';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../../libs/i18n/common.config';
import { Space } from '../../common/layout';
import useUser from '../../../libs/hooks/useUser';
import { formattedCurrency } from '@candypig-monorepo/utils';
import ResponsiveModal from '../../common/responsive-modal';
import Divider from '../../common/divider';
import DollarSign from '../../common/DollarSign';
import {
  GachaNftPopover,
  TotalAssetsPopover,
} from '../../common/assets-help-tooltip';

// --- Keeping this in case we need this back
// const StyledGreenCircle = styled('span')(({ theme }) => ({
//   width: 24,
//   height: 24,
//   borderRadius: '100%',
//   padding: 2,
//   backgroundColor: theme.token.colorInfo,
//   [media.sm]: {
//     width: 28,
//     height: 28,
//     padding: theme.token.sizeXXS,
//   },
// }));

export const DailyProfitLoseSummaryButton = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useUser();

  const dailyPL = user?.userSummary?.totalPl;

  const totalAssets = +user?.userSummary?.currentTotalAssets;

  const summaryItems = [
    {
      tKey: 'AvailableBalance',
      value: +user?.userSummary.availableBalance,
    },
    {
      tKey: 'CandyPoint',
      value: +user?.userSummary.point,
    },
    {
      tKey: 'GachaNFT',
      value: +user?.userSummary.gachaData.amount,
      lKey: 'Owned',
      lValue: +user?.userSummary.gachaData.count,
      rKey: 'Borrowed',
      rValue: -user?.userSummary.collateralisedAmount,
    },
    {
      tKey: 'GachaBid',
      value: Math.abs(+user?.userSummary.biddingData.amount),
      lKey: 'Count',
      lValue: +user?.userSummary.biddingData?.count,
    },
    {
      tKey: 'BalanceUsedInCandyland',
      value: Math.abs(+user?.userSummary.betAmount),
    },
  ];

  return (
    <>
      <Button
        responsive
        type={'text'}
        style={{ gap: 8 }}
        onClick={() => setIsModalOpen(true)}
      >
        {/*<StyledGreenCircle>*/}
        {/*  <Image*/}
        {/*    src={'/images/coin-flipping-3.gif'}*/}
        {/*    width={28}*/}
        {/*    height={28}*/}
        {/*    alt={'Language'}*/}
        {/*  />*/}
        {/*</StyledGreenCircle>*/}
        <Space
          size={'xxs'}
          direction={'horizontal'}
          align={'baseline'}
          justify={'center'}
        >
          <Text size={'sm'} color={'default'}>
            <DollarSign />
            {formattedCurrency(+user?.userSummary.availableBalance, 2, '-')}
          </Text>
          {/*<Text size={'xs'} color={todayPL >= 0 ? 'secondary' : 'error'}>*/}
          {/*  {todayPL >= 0 ? '+' : ''}*/}
          {/*  {formattedCurrency(todayPL, 2, '-')}*/}
          {/*</Text>*/}
        </Space>
      </Button>
      <ResponsiveModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        size={'large'}
        centered
        closable
        width={'50vw'}
      >
        <Space full direction={'horizontal'} justify={'space-between'}>
          <Space full direction={'vertical'} size={'xxs'}>
            <Space>
              <Heading level={4} color={'primary'}>
                {t('TotalAssets')}
              </Heading>
              <Space>
                <TotalAssetsPopover />
              </Space>
            </Space>
            <Text color={'default'}> {t('AsOfToday')}</Text>
          </Space>
          <Space full direction={'vertical'} size={'xxs'}>
            <Heading level={4} color={'primary'} align={'right'}>
              <DollarSign />
              {formattedCurrency(totalAssets, 2, '-')}
            </Heading>
            <Text
              size={'xs'}
              align={'right'}
              color={dailyPL >= 0 ? 'secondary' : 'error'}
            >
              {dailyPL >= 0 ? '+' : ''}
              {formattedCurrency(dailyPL, 2, '-')}
            </Text>
          </Space>
        </Space>
        <Divider size={'xl'} />
        <Space full direction={'vertical'} size={'xl'}>
          {summaryItems.map(({ tKey, value, lKey, lValue, rKey, rValue }) => (
            <Space
              key={tKey}
              full
              direction={'horizontal'}
              justify={'space-between'}
            >
              <Space>
                <Text color={'default'} weight={'400'}>
                  {t(tKey)}
                </Text>
                {lKey && (
                  <>
                    <Text color={'textDisabled'} weight={'400'}>
                      ({t(lKey)} : {lValue})
                    </Text>
                    {lKey === 'Owned' && (
                      <Space>
                        <GachaNftPopover />
                      </Space>
                    )}
                  </>
                )}
              </Space>
              <Space direction={'vertical'} size={'xxs'} align={'end'}>
                <Text color={'default'} weight={'400'}>
                  <DollarSign />
                  {formattedCurrency(Math.abs(value), 2, '-')}
                </Text>
                {rKey && (
                  <Text color={'textDisabled'} weight={'400'}>
                    ({t(rKey)} : <DollarSign />
                    {formattedCurrency(Math.abs(rValue), 2, '-')})
                  </Text>
                )}
              </Space>
            </Space>
          ))}
        </Space>
      </ResponsiveModal>
    </>
  );
};

export default DailyProfitLoseSummaryButton;
