import React from 'react';
import Button from '../../common/Button';
import { Link } from '../../common/typography';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../../libs/i18n/common.config';
import type { MenuItem } from './const';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';

const StyledButton = styled(Button)(() => ({
  ['&.ant-btn']: {
    height: 38,
    [media.lg]: {
      height: 48,
    },
  },
}));

export const MenuButton = ({ item }: { item: MenuItem }) => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  return (
    <Link key={item.url} color={'primary'} weight={'bolder'} href={item.url}>
      <StyledButton
        responsive
        type={item.tKey === 'login' ? 'link' : 'primary'}
      >
        {t(item.tKey)}
      </StyledButton>
    </Link>
  );
};

export default MenuButton;
