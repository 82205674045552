import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { useThemeMode } from '../../../libs/hooks/useThemeMode';
import { themeMode } from '../../../constant/themes';

type Props = Omit<ImageProps, 'src' | 'width' | 'height' | 'alt'> & {
  width?: number;
  height?: number;
};

const Logo = ({ width = 180, height = 40, fill, ...props }: Props) => {
  const { selectedThemeMode } = useThemeMode();
  return (
    <Image
      src={`/images/logo/${
        selectedThemeMode === themeMode.light ? 'logo' : 'white_text_logo'
      }.svg`}
      width={fill ? undefined : width}
      height={fill ? undefined : height}
      alt="candypig"
      fill={fill}
      {...props}
    />
  );
};

export default Logo;
