import { useRouter } from 'next/router';
import useAuth from '../hooks/useAuth';
import { useEffect } from 'react';
import { url, urlRequireAuth } from '../../constant/url';

export const usePageRequiresLogin = () => {
  const router = useRouter();
  const { authenticated, user, isLoading } = useAuth();
  const { pathname } = router;

  useEffect(() => {
    if (isLoading) return;

    if (
      authenticated &&
      !user.isEmailVerified &&
      !router.pathname.includes(url.verify)
    ) {
      router.push(url.verify);
      return;
    }

    if (
      !authenticated &&
      urlRequireAuth.find((x) => router.pathname.includes(x))
    ) {
      router.push(url.login);
      return;
    }
  }, [authenticated, user, isLoading, pathname, router]);
};
