import { Layout } from 'antd';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { CustomFooter } from './custom-footer';
import MainMenu from './menu/main-menu';
import { CustomerServiceButton } from './customer-service-button';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';

export const StyledContentContainer = styled(Layout.Content)(({ theme }) => ({
  maxWidth: 1200,
  padding: `${theme.token.sizeXS}px ${theme.token.sizeMD}px`,
  [media.sm]: {
    padding: `${theme.token.sizeSM}px ${theme.token.sizeLG}px`,
  },
  [media.md]: {
    padding: `${theme.token.sizeMD}px ${theme.token.sizeLG}px`,
  },
  [media.xl]: {
    minWidth: 1200,
    margin: 'auto',
    padding: `${theme.token.sizeMD}px 0px ${theme.token.sizeLG}px 0px`,
  },
}));

const StyledContentFull = styled(Layout.Content)(() => ({
  width: '100%',
}));

type Props = {
  children: ReactNode;
  fullWidth?: boolean;
  withPigImg?: boolean;
  extraBottom?: boolean;
};

const ContentContainer = styled.div<Props>(({ extraBottom }) => ({
  overflowX: 'hidden',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: extraBottom ? '154px' : '81px',
  [media.md]: {
    marginBottom: 0,
  },
}));

export const LayoutMain: FC<Props> = ({
  children,
  fullWidth = false,
  ...props
}) => {
  const Content = fullWidth ? StyledContentFull : StyledContentContainer;
  return (
    <Layout>
      <MainMenu />
      <ContentContainer {...props}>
        <Content>{children}</Content>
        <CustomFooter />
        {process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_EN && (
          <CustomerServiceButton />
        )}
      </ContentContainer>
    </Layout>
  );
};
