import Image from 'next/legacy/image';

type Props = {
  size?: number;
};

const IconPigCoin = ({ size = 14 }: Props) => (
  <Image
    src="/images/ico_pig_coin.svg"
    width={size}
    height={size}
    alt="pig_coin"
  />
);

export default IconPigCoin;
