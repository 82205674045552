import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import styled from '@emotion/styled';
import { DesktopMenu } from './desktop/desktop-menu';
import { media } from '@candypig-monorepo/types';
import { useScrollDirection } from '../../../../libs/hooks/useScrollDirection';
import { MobileMenu } from './mobile/mobile-menu';
import { usePageRequiresLogin } from '../../../../libs/helpers/usePageRequiresLogin';
import { useBreakpoint } from '../../../../libs/helpers/useBreakpoint';

const StyledHeader = styled(Layout.Header)((props) => ({
  backgroundColor: props.theme.token.colorBgContainer,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000,
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  height: 'auto',
  position: 'sticky',
  top: 0,
  lineHeight: `${props.theme.token.sizeXXL}px`,
  padding: `${props.theme.token.sizeXS}px ${props.theme.token.sizeSM}px`,
  [media.sm]: {
    padding: `${props.theme.token.sizeMD}px ${props.theme.token.sizeLG}px`,
  },
  [media.md]: {
    padding: `${props.theme.token.sizeXS}px ${props.theme.token.sizeLG}px`,
  },
  [media.lg]: {
    lineHeight: `64px`,
  },
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '500ms',

  '&.transparent': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },

  '&.hide': {
    top: '-79px',
  },
}));

export const MainMenu = () => {
  const { lg } = useBreakpoint();
  const [transparentHeader, setTransparentHeader] = useState(true);
  const scrollDirection = useScrollDirection();

  usePageRequiresLogin();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setTransparentHeader(scrollPosition <= 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledHeader
      className={[
        transparentHeader ? 'transparent' : '',
        scrollDirection === 'down' ? 'hide' : '',
      ].join(' ')}
    >
      {lg ? <DesktopMenu /> : <MobileMenu />}
    </StyledHeader>
  );
};

export default MainMenu;
