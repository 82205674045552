import { Space } from '../../../../common/layout';
import { Link } from '../../../../common/typography';
import { url } from '../../../../../constant/url';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import { Menu } from 'antd';
import { useRouter } from 'next/router';
import { MENU_ITEMS, useMenuItemsBasedOnAuth } from '../../const';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../../../../libs/i18n/common.config';
import Button from '../../../../common/Button';
import Logo from '../../../../common/icon/logo';
import { useTheme } from '../../../../../libs/hooks/useTheme';

const StyledHeaderInner = styled(Space)({
  [media.sm_max]: {
    padding: '8px 16px',
  },
  [media.xl]: {
    maxWidth: '1200px',
    margin: 'auto',
  },
});

const StyledIconBox = styled('div')({
  display: 'flex',
  width: 120,
  height: 40,
  cursor: 'pointer',
  position: 'relative',
  [media.lg]: {
    width: 180,
  },
});

const StyledMenu = styled(Menu)(({ theme }) => ({
  ['&.ant-menu-root']: {
    backgroundColor: 'transparent',
    borderBottom: '0',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'end',
    width: '100%',
    ['.ant-menu-item']: {
      paddingInline: theme.token.sizeXS,
      display: 'flex',
      alignItems: 'center',
      [media.lg]: {
        paddingInline: theme.token.sizeSM,
      },
      ['&:last-of-type']: {
        paddingRight: 0,
      },
    },
    ['.ant-menu-item .ant-btn-link']: {
      fontFamily: '"NunitoVariable", sans-serif',
      fontSize: theme.token.fontSize,
      padding: 0,
      [media.lg]: {
        fontSize: theme.token.fontSizeLG,
      },
    },
    ['.header-button, .header-icon']: {
      display: 'flex',
      alignItems: 'center',
    },
    ['.ant-menu-item:hover, .ant-menu-item-selected, .ant-menu-item:hover, .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)']:
      {
        ['.ant-btn-link span']: {
          textDecoration: 'underline',
          textUnderlineOffset: 8,
        },
      },
  },
}));

export const DesktopMenu = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const router = useRouter();
  const { pathname } = router;
  const { sizeLG } = useTheme();

  const leftItems = useMenuItemsBasedOnAuth([
    MENU_ITEMS.marketplace,
    // {
    //   ...MENU_ITEMS.gacha_market,
    //   tKey: 'nftMarkets',
    // },
    // MENU_ITEMS.candyland,
    // MENU_ITEMS.lending,
  ]);

  const rightItems = useMenuItemsBasedOnAuth([
    MENU_ITEMS.login,
    MENU_ITEMS.register,
    MENU_ITEMS.dailyProfitLose,
    MENU_ITEMS.dashboard,
    MENU_ITEMS.notification,
    MENU_ITEMS.settings,
  ]);

  const transformToDesktopItems = (items) => {
    return items.map((item) => {
      const Comp = item.component;

      return {
        url: item.url,
        key: item.url,
        icon: null,
        ...(item.component && {
          component: item.component,
        }),
        label: (
          <>
            {item.component && <Comp key={item.tKey} item={item} />}
            {!item.component && (
              <Link
                key={item.url}
                color={'primary'}
                weight={'bolder'}
                href={item.url}
              >
                <Button block type={'link'}>
                  {t(item.tKey)}
                </Button>
              </Link>
            )}
          </>
        ),
      };
    });
  };

  const desktopLeftItems = transformToDesktopItems(leftItems);
  const desktopRightItems = transformToDesktopItems(rightItems);

  return (
    <StyledHeaderInner full justify={'space-between'}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: sizeLG,
        }}
      >
        <Link href={url.landing}>
          <StyledIconBox>
            <Logo fill />
          </StyledIconBox>
        </Link>
        <StyledMenu
          defaultSelectedKeys={[pathname]}
          mode="horizontal"
          disabledOverflow={true}
          items={desktopLeftItems}
          triggerSubMenuAction="click"
        />
      </div>
      <StyledMenu
        defaultSelectedKeys={[pathname]}
        mode="horizontal"
        disabledOverflow={true}
        items={desktopRightItems}
        triggerSubMenuAction="click"
      />
    </StyledHeaderInner>
  );
};
