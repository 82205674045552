import type { ThemeColor } from '../typography/useColor';
import { useColor } from '../typography/useColor';

type Props = {
  size?: number;
  color?: ThemeColor;
};

const IconList = ({ size = 14, color = 'white' }: Props) => {
  const svgColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list">
        <path
          id="Vector"
          d="M14.25 1.75H2.75C2.47344 1.75 2.25 1.97344 2.25 2.25V13.75C2.25 14.0266 2.47344 14.25 2.75 14.25H14.25C14.5266 14.25 14.75 14.0266 14.75 13.75V2.25C14.75 1.97344 14.5266 1.75 14.25 1.75ZM13.625 13.125H3.375V2.875H13.625V13.125ZM8.1875 6.25H11.0625C11.1313 6.25 11.1875 6.19375 11.1875 6.125V5.375C11.1875 5.30625 11.1313 5.25 11.0625 5.25H8.1875C8.11875 5.25 8.0625 5.30625 8.0625 5.375V6.125C8.0625 6.19375 8.11875 6.25 8.1875 6.25ZM8.1875 8.5H11.0625C11.1313 8.5 11.1875 8.44375 11.1875 8.375V7.625C11.1875 7.55625 11.1313 7.5 11.0625 7.5H8.1875C8.11875 7.5 8.0625 7.55625 8.0625 7.625V8.375C8.0625 8.44375 8.11875 8.5 8.1875 8.5ZM8.1875 10.75H11.0625C11.1313 10.75 11.1875 10.6938 11.1875 10.625V9.875C11.1875 9.80625 11.1313 9.75 11.0625 9.75H8.1875C8.11875 9.75 8.0625 9.80625 8.0625 9.875V10.625C8.0625 10.6938 8.11875 10.75 8.1875 10.75ZM5.8125 5.75C5.8125 5.91576 5.87835 6.07473 5.99556 6.19194C6.11277 6.30915 6.27174 6.375 6.4375 6.375C6.60326 6.375 6.76223 6.30915 6.87944 6.19194C6.99665 6.07473 7.0625 5.91576 7.0625 5.75C7.0625 5.58424 6.99665 5.42527 6.87944 5.30806C6.76223 5.19085 6.60326 5.125 6.4375 5.125C6.27174 5.125 6.11277 5.19085 5.99556 5.30806C5.87835 5.42527 5.8125 5.58424 5.8125 5.75ZM5.8125 8C5.8125 8.16576 5.87835 8.32473 5.99556 8.44194C6.11277 8.55915 6.27174 8.625 6.4375 8.625C6.60326 8.625 6.76223 8.55915 6.87944 8.44194C6.99665 8.32473 7.0625 8.16576 7.0625 8C7.0625 7.83424 6.99665 7.67527 6.87944 7.55806C6.76223 7.44085 6.60326 7.375 6.4375 7.375C6.27174 7.375 6.11277 7.44085 5.99556 7.55806C5.87835 7.67527 5.8125 7.83424 5.8125 8ZM5.8125 10.25C5.8125 10.4158 5.87835 10.5747 5.99556 10.6919C6.11277 10.8092 6.27174 10.875 6.4375 10.875C6.60326 10.875 6.76223 10.8092 6.87944 10.6919C6.99665 10.5747 7.0625 10.4158 7.0625 10.25C7.0625 10.0842 6.99665 9.92527 6.87944 9.80806C6.76223 9.69085 6.60326 9.625 6.4375 9.625C6.27174 9.625 6.11277 9.69085 5.99556 9.80806C5.87835 9.92527 5.8125 10.0842 5.8125 10.25Z"
          fill={svgColor}
        />
      </g>
    </svg>
  );
};

export default IconList;
