import type { ThemeColor } from '../typography/useColor';
import { useColor } from '../typography/useColor';

type Props = {
  size?: number;
  color?: ThemeColor;
};

const IconWallet = ({ size = 14, color = 'white' }: Props) => {
  const svgColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="iconoir:wallet">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 7.74996C4.66848 7.74996 4.35054 7.88166 4.11612 8.11608C3.8817 8.3505 3.75 8.66844 3.75 8.99996V18C3.75 18.3315 3.8817 18.6494 4.11612 18.8838C4.35054 19.1183 4.66848 19.25 5 19.25H19C19.3315 19.25 19.6495 19.1183 19.8839 18.8838C20.1183 18.6494 20.25 18.3315 20.25 18V8.99996C20.25 8.66844 20.1183 8.3505 19.8839 8.11608C19.6495 7.88166 19.3315 7.74996 19 7.74996H5ZM3.05546 7.05542C3.57118 6.53969 4.27065 6.24996 5 6.24996H19C19.7293 6.24996 20.4288 6.53969 20.9445 7.05542C21.4603 7.57114 21.75 8.27061 21.75 8.99996V18C21.75 18.7293 21.4603 19.4288 20.9445 19.9445C20.4288 20.4602 19.7293 20.75 19 20.75H5C4.27065 20.75 3.57118 20.4602 3.05546 19.9445C2.53973 19.4288 2.25 18.7293 2.25 18V8.99996C2.25 8.27061 2.53973 7.57114 3.05546 7.05542Z"
            fill={svgColor}
          />
          <path
            d="M16.5 14C16.3674 14 16.2402 13.9473 16.1464 13.8535C16.0527 13.7597 16 13.6326 16 13.5C16 13.3674 16.0527 13.2402 16.1464 13.1464C16.2402 13.0526 16.3674 13 16.5 13C16.6326 13 16.7598 13.0526 16.8536 13.1464C16.9473 13.2402 17 13.3674 17 13.5C17 13.6326 16.9473 13.7597 16.8536 13.8535C16.7598 13.9473 16.6326 14 16.5 14Z"
            fill={svgColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5 13.75C16.5663 13.75 16.6299 13.7236 16.6768 13.6767C16.7237 13.6298 16.75 13.5663 16.75 13.5C16.75 13.4337 16.7237 13.3701 16.6768 13.3232C16.6299 13.2763 16.5663 13.25 16.5 13.25C16.4337 13.25 16.3701 13.2763 16.3232 13.3232C16.2763 13.3701 16.25 13.4337 16.25 13.5C16.25 13.5663 16.2763 13.6298 16.3232 13.6767C16.3701 13.7236 16.4337 13.75 16.5 13.75ZM15.6161 12.6161C15.8505 12.3817 16.1685 12.25 16.5 12.25C16.8315 12.25 17.1495 12.3817 17.3839 12.6161C17.6183 12.8505 17.75 13.1684 17.75 13.5C17.75 13.8315 17.6183 14.1494 17.3839 14.3838C17.1495 14.6183 16.8315 14.75 16.5 14.75C16.1685 14.75 15.8505 14.6183 15.6161 14.3838C15.3817 14.1494 15.25 13.8315 15.25 13.5C15.25 13.1684 15.3817 12.8505 15.6161 12.6161Z"
            fill={svgColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2919 2.94625C15.6991 2.83773 16.1258 2.82426 16.5391 2.90687C16.9523 2.98948 17.3411 3.16597 17.6753 3.4227C18.0095 3.67944 18.2802 4.00955 18.4666 4.38755C18.6529 4.76555 18.7499 5.18132 18.75 5.60275V6.99996H17.25V5.60317C17.25 5.6031 17.25 5.60324 17.25 5.60317C17.2499 5.41168 17.2058 5.22256 17.1212 5.0508C17.0365 4.87899 16.9134 4.72894 16.7615 4.61224C16.6096 4.49554 16.4329 4.41532 16.245 4.37777C16.0572 4.34022 15.8633 4.34634 15.6782 4.39564C15.6782 4.39565 15.6783 4.39563 15.6782 4.39564L4.67823 7.32864C4.41201 7.39958 4.17659 7.55652 4.00874 7.775C3.8409 7.99348 3.74994 8.26129 3.75 8.53679V8.99996H2.25L2.25 8.53713C2.25 8.53707 2.25 8.53718 2.25 8.53713C2.2499 7.93107 2.45001 7.34178 2.81924 6.86117C3.18847 6.38055 3.70614 6.03537 4.29177 5.87928C4.29174 5.87929 4.29181 5.87927 4.29177 5.87928L15.2919 2.94625Z"
            fill={svgColor}
          />
        </g>
      </g>
    </svg>
  );
};

export default IconWallet;
