import type { FC } from 'react';
import { MobileTopMenu } from './mobile-top-menu-bar';
import dynamic from 'next/dynamic';

const MobileBottomMenu = dynamic(() => import('./mobile-bottom-menu'), {
  ssr: false,
});

export const MobileMenu: FC = () => {
  return (
    <>
      <MobileTopMenu />
      <MobileBottomMenu />
    </>
  );
};
