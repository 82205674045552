export const url = {
  landing: `/`,
  my_profile: '/my-profile',
  deposit: '/deposit',
  nft_items: '/nft-items',
  nft_items_detail: '/nft-items/detail',
  nft_detail: `/nft/detail`,
  auction_nft_detail: `/auction/nft/detail`,
  lottery_detail: `/lottery/detail`,
  request_account_name: `/request-account-name`,
  lending: `/`, // Revert to /lending when page is enabled
  candyland: `/`, // Revert to /candyland when page is enabled
  transaction_history: '/transaction-history',
  withdrawal: '/withdraw',
  preference: '/preference',
  login: '/login',
  register: '/register',
  candyland_record: '/candyland-record',
  verify: `/verify`,
  forgot_password: `/forgot-password`,
  business: '/business',
  gacha_market: '/marketplace', // Revert to /gacha-market when page is enabled, now redirect all to marketplace
  marketplace: '/marketplace',
  nft_records: '/nft-records',
};

export const urlRequireAuth = [
  url.my_profile,
  url.nft_items,
  url.nft_items_detail,
  url.transaction_history,
  url.candyland_record,
  url.deposit,
  url.withdrawal,
  url.preference,
  url.nft_records,
  url.business,
];

const gitbookBaseUrl = 'https://candypig.gitbook.io';

export const gitbookUrl = {
  aboutUs: {
    en: gitbookBaseUrl + '/candypig-1/about-us',
    'zh-HK': gitbookBaseUrl + '/candypig-1/v/zh/guan-yu-wo-men',
    es: gitbookBaseUrl + '/candypig-1/v/sp/sobre-nosotros',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/undefined-4',
    vi: gitbookBaseUrl + '/candypig-1/v/tieng-viet/ve-chung-toi',
    th: gitbookBaseUrl + '/candypig-1/v/th/undefined-4',
  },
  transparency: {
    en: gitbookBaseUrl + '/candypig-1/transparency',
    'zh-HK': gitbookBaseUrl + '/candypig-1/v/zh/tou-ming-du-sheng-ming',
    es: gitbookBaseUrl + '/candypig-1/v/sp/transparencia',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/undefined-5',
    vi: gitbookBaseUrl + '/candypig-1/v/tieng-viet/minh-bach',
    th: gitbookBaseUrl + '/candypig-1/v/th/undefined-5',
  },
  privacyPolicy: {
    en: gitbookBaseUrl + '/candypig-1/privacy-policy',
    'zh-HK': gitbookBaseUrl + '/candypig-1/v/zh/yin-si-zheng-ce',
    es: gitbookBaseUrl + '/candypig-1/v/sp/politica-de-privacidad',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/undefined-1',
    vi: gitbookBaseUrl + '/candypig-1/v/tieng-viet/chinh-sach-bao-mat',
    th: gitbookBaseUrl + '/candypig-1/v/th/undefined-1',
  },
  termsAndConditions: {
    en: gitbookBaseUrl + '/candypig-1/terms-and-conditions',
    'zh-HK': gitbookBaseUrl + '/candypig-1/v/zh/shi-yong-tiao-kuan',
    es: gitbookBaseUrl + '/candypig-1/v/sp/terminos-y-condiciones',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/undefined-2',
    vi: gitbookBaseUrl + '/candypig-1/v/tieng-viet/cac-dieu-khoan-va-dieu-kien',
    th: gitbookBaseUrl + '/candypig-1/v/th/undefined-2',
  },
  guide: {
    en: gitbookBaseUrl + '/candypig-1/guide',
    'zh-HK': gitbookBaseUrl + '/candypig-1/v/zh/zhi-nan',
    es: gitbookBaseUrl + '/candypig-1/v/sp/guia-rapida',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/undefined',
    vi: gitbookBaseUrl + '/candypig-1/v/tieng-viet/huong-dan-nhanh',
    th: gitbookBaseUrl + '/candypig-1/v/th/undefined',
  },
  quickGuide: {
    en: gitbookBaseUrl + '/candypig-1/quick-guide',
    'zh-HK': gitbookBaseUrl + '/candypig-1/v/zh/shi-yong-zhi-nan',
    es: gitbookBaseUrl + '/candypig-1/v/sp/',
    ko: gitbookBaseUrl + '/candypig-1/v/sp/guia-rapida',
    vi: gitbookBaseUrl + '/candypig-1/v/tieng-viet/huong-dan-nhanh',
    th: gitbookBaseUrl + '/candypig-1/v/th/undefined',
  },
  disclaimer: {
    en: gitbookBaseUrl + '/candypig-1/disclaimer',
    'zh-HK': gitbookBaseUrl + '/candypig-1/v/zh/mian-ze-sheng-ming',
    es: gitbookBaseUrl + '/candypig-1/v/sp/descargo-de-responsabilidad',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/undefined-3',
    vi:
      gitbookBaseUrl + '/candypig-1/v/tieng-viet/tuyen-bo-tu-choi-trach-nhiem',
    th: gitbookBaseUrl + '/candypig-1/v/th/undefined-2',
  },
  introduction: {
    en: gitbookBaseUrl + '/candypig-1/candypig-getting-started/introduction',
    'zh-HK':
      gitbookBaseUrl + '/candypig-1/v/zh/candypig-kai-shi-shi-yong/jie-shao',
    es:
      gitbookBaseUrl + '/candypig-1/v/sp/candypig-primeros-pasos/introduccion',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/candypig/undefined',
    vi: gitbookBaseUrl + '/candypig-1/v/tieng-viet/candypig-bat-dau/gioi-thieu',
    th: gitbookBaseUrl + '/candypig-1/v/th/candypig/undefined',
  },
  gachaRaffle: {
    en:
      gitbookBaseUrl +
      '/candypig-1/candypig-getting-started/introduction/nft-market/gacha-raffle',
    'zh-HK':
      gitbookBaseUrl +
      '/candypig-1/v/zh/candypig-kai-shi-shi-yong/jie-shao/nft-shi-chang/gacha-chou-jiang-huo-dong',
    es:
      gitbookBaseUrl +
      '/candypig-1/v/sp/candypig-primeros-pasos/introduccion/mercado-nft/rifa-gacha',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/candypig/undefined/nft/undefined',
    vi:
      gitbookBaseUrl +
      '/candypig-1/v/tieng-viet/candypig-bat-dau/gioi-thieu/thi-truong-nft/xo-so-gacha',
    th:
      gitbookBaseUrl +
      '/candypig-1/v/th/candypig/undefined/undefined/undefined',
  },
  nftPurpose: {
    en:
      gitbookBaseUrl +
      '/candypig-1/candypig-getting-started/introduction/nft-market/akashic-chain-naming-service-acns',
    'zh-HK':
      gitbookBaseUrl +
      '/candypig-1/v/zh/candypig-kai-shi-shi-yong/jie-shao/nft-shi-chang/akashic-chain-yu-ming-fu-wu-acns',
    es:
      gitbookBaseUrl +
      '/candypig-1/v/sp/candypig-primeros-pasos/introduccion/mercado-nft',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/candypig/undefined/nft',
    vi:
      gitbookBaseUrl +
      '/candypig-1/v/tieng-viet/candypig-bat-dau/gioi-thieu/thi-truong-nft',
    th:
      gitbookBaseUrl +
      '/candypig-1/v/th/candypig/undefined/undefined/akashic-aas',
  },
  lending: {
    en:
      gitbookBaseUrl +
      '/candypig-1/candypig-getting-started/introduction/lending',
    'zh-HK':
      gitbookBaseUrl +
      '/candypig-1/v/zh/candypig-kai-shi-shi-yong/jie-shao/dai-kuan',
    es:
      gitbookBaseUrl +
      '/candypig-1/v/sp/candypig-primeros-pasos/introduccion/prestamo',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/candypig/undefined/undefined',
    vi:
      gitbookBaseUrl +
      '/candypig-1/v/tieng-viet/candypig-bat-dau/gioi-thieu/cho-vay',
    th: gitbookBaseUrl + '/candypig-1/v/th/candypig/undefined/undefined-2',
  },
  candyland: {
    en:
      gitbookBaseUrl +
      '/candypig-1/candypig-getting-started/introduction/candyland',
    'zh-HK':
      gitbookBaseUrl +
      '/candypig-1/v/zh/candypig-kai-shi-shi-yong/jie-shao/candyland-you-hu',
    es:
      gitbookBaseUrl +
      '/candypig-1/v/sp/candypig-primeros-pasos/introduccion/tierra-de-dulces',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/candypig/undefined/candyland',
    vi:
      gitbookBaseUrl +
      '/candypig-1/v/tieng-viet/candypig-bat-dau/gioi-thieu/candyland',
    th:
      gitbookBaseUrl +
      '/candypig-1/candypig-getting-started/introduction/candyland',
  },
  candylandBlackAnts: {
    en:
      gitbookBaseUrl +
      '/candypig-1/candypig-getting-started/introduction/candyland',
    'zh-HK':
      gitbookBaseUrl +
      '/candypig-1/v/zh/candypig-kai-shi-shi-yong/jie-shao/candyland',
    es:
      gitbookBaseUrl +
      '/candypig-1/v/sp/candypig-primeros-pasos/introduccion/tierra-de-dulces',
    ko: gitbookBaseUrl + '/candypig-1/v/kr/candypig/undefined/candyland',
    vi:
      gitbookBaseUrl +
      '/candypig-1/v/tieng-viet/candypig-bat-dau/gioi-thieu/candyland',
    th: gitbookBaseUrl + '/candypig-1/v/th/candypig/undefined/undefined-1',
  },
};
