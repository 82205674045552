import type { FC, ReactNode } from 'react';
import type { ModalProps as AntdModalProps } from 'antd';
import { Modal as AntdModal } from 'antd';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StyledAntdModal = styled(AntdModal)(({ theme }) => ({
  ['.ant-modal-content']: {
    background: theme.components.Modal.colorBgContainer,
    padding: theme.token.sizeLG,
    [media.lg]: {
      padding: theme.token.sizeXXL,
    },
  },
  ['.ant-modal-title']: {
    textAlign: 'center',
    fontSize: theme.token.fontSizeHeading4,
  },
  ['.ant-modal-body']: {
    padding: `${theme.token.sizeSM}px 0`,
    [media.lg]: {
      padding: `0`,
    },
  },
  ['.ant-modal-close']: {
    height: 36,
    width: 36,
    top: theme.token.sizeSM,
    insetInlineEnd: theme.token.sizeSM,
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: '36px',
  height: '36px',
  justifyContent: 'center',
  backgroundColor: theme.token.colorPrimary,
  borderRadius: '100%',
}));

type ModalProps = AntdModalProps & {
  children: ReactNode;
};
const CloseSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#FFFFFF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const CloseIcon = (props: Partial<CustomIconComponentProps>) => (
  <StyledIcon component={CloseSvg} {...props} />
);

const Modal: FC<ModalProps> = ({ children, closable = false, ...props }) => {
  return (
    // prevent event propagate to modal: https://github.com/ant-design/ant-design/issues/16004
    <div onClick={(e) => e.stopPropagation()}>
      <StyledAntdModal closable={closable} closeIcon={<CloseIcon />} {...props}>
        {children}
      </StyledAntdModal>
    </div>
  );
};

export default Modal;
