import type { GlobalToken } from 'antd';
import { Popover } from 'antd';
import Image from 'next/legacy/image';
import { useTranslation } from 'react-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import styled from '@emotion/styled';
import { useThemeMode } from '../../libs/hooks/useThemeMode';
import { useTheme } from '../../libs/hooks/useTheme';
import { useBreakpoint } from '../../libs/helpers/useBreakpoint';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

type StyledProps = {
  selectedThemeMode: string;
  passedTheme: GlobalToken;
  bottom?: boolean;
  origin?: string;
};

const AssetPopover = ({
  content,
  origin,
}: {
  content: ReactNode;
  origin?: string;
}) => {
  const { selectedThemeMode } = useThemeMode();
  const { xs } = useBreakpoint();
  const theme = useTheme();
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    const onTouchOutsidePopoverTouch = () => setPopoverOpen(false);
    window.removeEventListener('touchstart', onTouchOutsidePopoverTouch);
    window.addEventListener('touchstart', onTouchOutsidePopoverTouch);

    return () => {
      window.removeEventListener('touchmove', onTouchOutsidePopoverTouch);
    };
  }, []);

  return (
    <Popover
      open={xs ? popoverOpen : undefined}
      autoAdjustOverflow={false}
      arrow={false}
      content={content}
      placement={!xs ? 'right' : origin === 'user-balance' ? 'right' : 'bottom'}
      overlayInnerStyle={{
        backgroundColor:
          selectedThemeMode === 'light' ? 'white' : theme.colorBgContainer,
      }}
    >
      <Image
        onClick={xs ? () => setPopoverOpen((prev) => !prev) : undefined}
        width={16}
        height={16}
        src={'/images/info.svg'}
        style={{ cursor: 'pointer' }}
      />
    </Popover>
  );
};

const StyledTotal = styled.div<StyledProps>`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: 99999;
    top: ${(props) =>
      props.bottom && props.origin !== 'user-balance' ? '-12%' : '47%'};
    left: ${(props) =>
      props.bottom && props.origin !== 'user-balance' ? '45%' : '-13.1%'};
    transform: ${(props) =>
      props.bottom && props.origin !== 'user-balance'
        ? 'translateX(50%)'
        : 'translateX(50%) rotate(-90deg)'};
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: ${(props) =>
      props.selectedThemeMode === 'light'
        ? '11px solid white'
        : `11px solid  ${props.passedTheme.colorBgContainer}`};
  }
`;

const StyledGacha = styled.div<StyledProps>`
  position: relative;
  z-index: 99999;
  &::before {
    content: '';
    position: absolute;
    top: ${(props) => (props.bottom && origin ? '-22%' : '40%')};
    left: ${(props) => (props.bottom ? '45%' : '-19%')};
    transform: ${(props) =>
      props.bottom ? 'translateX(50%)' : 'translateX(50%) rotate(-90deg)'};
    border-left: 8.2px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: ${(props) =>
      props.selectedThemeMode === 'light'
        ? '9px solid white'
        : `9px solid  ${props.passedTheme.colorBgContainer}`};
  }

  }
`;

export const TotalAssetsPopover = ({ origin }: { origin?: string }) => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { selectedThemeMode } = useThemeMode();
  const { xs } = useBreakpoint();
  const theme = useTheme();

  const totalAssetsPopoverInfo = (
    <StyledTotal
      bottom={xs}
      selectedThemeMode={selectedThemeMode}
      passedTheme={theme}
      style={{ maxWidth: '288px', padding: '16px' }}
      origin={origin}
    >
      <p style={{ fontSize: 12, marginBottom: '8px' }}>
        <strong>{t('AssetsPopover.Title')}</strong>
      </p>
      <p style={{ fontSize: 10 }}>{t('AssetsPopover.TotalAssets')}</p>
      <p style={{ fontSize: 10 }}>{t('AssetsPopover.DailyPl')}</p>
    </StyledTotal>
  );

  return <AssetPopover origin={origin} content={totalAssetsPopoverInfo} />;
};

export const GachaNftPopover = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { selectedThemeMode } = useThemeMode();
  const { xs } = useBreakpoint();
  const theme = useTheme();

  const totalAssetsPopoverInfo = (
    <StyledGacha
      bottom={xs}
      selectedThemeMode={selectedThemeMode}
      passedTheme={theme}
      style={{ maxWidth: '174px', padding: '16px' }}
    >
      <p style={{ fontSize: 12, marginBottom: '8px' }}>
        <strong>{t('GachaPopover.Title')}</strong>
      </p>
      <p style={{ fontSize: 10 }}>{t('GachaPopover.Description')}</p>
    </StyledGacha>
  );

  return <AssetPopover content={totalAssetsPopoverInfo} />;
};
