import type { FC } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import type { DividerProps as AntdDividerProps } from 'antd';
import { Divider as AntdDivider } from 'antd';
import type { SizeType } from './layout/useSize';
import { getSize } from './layout/useSize';
import type { TypographyColor } from './typography/useTypographyColor';
import { useTypographyColor } from './typography/useTypographyColor';
import { media } from '@candypig-monorepo/types';

export type DividerProps = AntdDividerProps & {
  color?: TypographyColor;
  size?: SizeType;
  sm?: {
    size?: SizeType;
  };
  md?: {
    size?: SizeType;
  };
  lg?: {
    size?: SizeType;
  };
};

const StyledDivider = styled(AntdDivider, {
  shouldForwardProp: (propName) => propName !== 'size',
})<DividerProps>(({ theme, type, size, sm, md, lg }) => ({
  ...(type === 'horizontal' && {
    marginTop: getSize(theme, size || 'lg'),
    marginBottom: getSize(theme, size || 'lg'),
    [media.sm]: {
      marginTop: getSize(theme, sm?.size || size || 'lg'),
      marginBottom: getSize(theme, sm?.size || size || 'lg'),
    },
    [media.md]: {
      marginTop: getSize(theme, md?.size || sm?.size || size || 'lg'),
      marginBottom: getSize(theme, md?.size || sm?.size || size || 'lg'),
    },
    [media.lg]: {
      marginTop: getSize(
        theme,
        lg?.size || md?.size || sm?.size || size || 'lg'
      ),
      marginBottom: getSize(
        theme,
        lg?.size || md?.size || sm?.size || size || 'lg'
      ),
    },
  }),
  ...(type === 'vertical' && {
    marginLeft: getSize(theme, size || 'lg'),
    marginRight: getSize(theme, size || 'lg'),
    height: '100%',

    [media.sm]: {
      marginLeft: getSize(theme, sm?.size || size || 'lg'),
      marginRight: getSize(theme, sm?.size || size || 'lg'),
    },
    [media.md]: {
      marginLeft: getSize(theme, md?.size || sm?.size || size || 'lg'),
      marginRight: getSize(theme, md?.size || sm?.size || size || 'lg'),
    },
    [media.lg]: {
      marginLeft: getSize(
        theme,
        lg?.size || md?.size || sm?.size || size || 'lg'
      ),
      marginRight: getSize(
        theme,
        lg?.size || md?.size || sm?.size || size || 'lg'
      ),
    },
  }),
}));

export const Divider: FC<DividerProps> = ({
  color,
  type = 'horizontal',
  ...props
}) => {
  const textColor = useTypographyColor(color);

  return (
    <StyledDivider
      type={type}
      {...props}
      style={{
        ...props.style,
        ...(color && { backgroundColor: textColor }),
      }}
    />
  );
};

export default Divider;
