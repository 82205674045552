import type { ThemeColor } from '../typography/useColor';
import { useColor } from '../typography/useColor';

type Props = {
  size?: number;
  color?: ThemeColor;
};

const IconUser = ({ size = 14, color = 'white' }: Props) => {
  const svgColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="user">
        <g id="Union">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 1.33337C6.65905 1.33337 5.16667 2.82576 5.16667 4.66671C5.16667 6.50766 6.65905 8.00004 8.5 8.00004C10.3409 8.00004 11.8333 6.50766 11.8333 4.66671C11.8333 2.82576 10.3409 1.33337 8.5 1.33337ZM6.5 4.66671C6.5 3.56214 7.39543 2.66671 8.5 2.66671C9.60457 2.66671 10.5 3.56214 10.5 4.66671C10.5 5.77128 9.60457 6.66671 8.5 6.66671C7.39543 6.66671 6.5 5.77128 6.5 4.66671Z"
            fill={svgColor}
          />
          <path
            d="M5.83333 9.33337C4.94928 9.33337 4.10143 9.68456 3.47631 10.3097C2.85119 10.9348 2.5 11.7827 2.5 12.6667V14C2.5 14.3682 2.79848 14.6667 3.16667 14.6667C3.53486 14.6667 3.83333 14.3682 3.83333 14V12.6667C3.83333 12.1363 4.04405 11.6276 4.41912 11.2525C4.79419 10.8774 5.3029 10.6667 5.83333 10.6667H11.1667C11.6971 10.6667 12.2058 10.8774 12.5809 11.2525C12.956 11.6276 13.1667 12.1363 13.1667 12.6667V14C13.1667 14.3682 13.4651 14.6667 13.8333 14.6667C14.2015 14.6667 14.5 14.3682 14.5 14V12.6667C14.5 11.7827 14.1488 10.9348 13.5237 10.3097C12.8986 9.68456 12.0507 9.33337 11.1667 9.33337H5.83333Z"
            fill={svgColor}
          />
        </g>
      </g>
    </svg>
  );
};

export default IconUser;
