import Image from 'next/image';
import styled from '@emotion/styled';
import type { FontSizeType } from '../typography/useFontSize';
import { media } from '@candypig-monorepo/types';

export type IConProps = {
  size?: number;
  sm?: {
    size?: FontSizeType;
  };
  md?: {
    size?: FontSizeType;
  };
  lg?: {
    size?: FontSizeType;
  };
};

export const StyledIconWrapper = styled.div<IConProps>(
  ({ size, sm, md, lg }) => ({
    height: size ?? 14,
    width: size ?? 14,
    [media.md]: {
      height: md?.size,
      width: md?.size,
    },
    [media.sm]: {
      height: sm?.size,
      width: sm?.size,
    },
    [media.lg]: {
      height: lg?.size,
      width: lg?.size,
    },
  })
);

const IconUSDT = ({ ...props }: IConProps) => {
  return (
    <StyledIconWrapper
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      {...props}
    >
      <Image src="/images/ico_usdt.svg" fill alt="usdt" {...props} />
    </StyledIconWrapper>
  );
};

export default IconUSDT;
