import { Drawer } from 'antd';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';
import React, { useState } from 'react';
import { NotificationList } from './notification-list';
import useNotification from '../../libs/hooks/useNotification';
import { commonConfig } from '../../libs/i18n/common.config';
import useUser from '../../libs/hooks/useUser';
import UserApi from '../../libs/api/userApi';
import Image from 'next/legacy/image';
import Button from '../common/Button';
import { Heading } from '../common/typography';
import Divider from '../common/divider';
import { Space } from '../common/layout';
import Empty from '../common/empty';
import { useBreakpoint } from '../../libs/helpers/useBreakpoint';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';

const StyledDiv = styled.div(() => ({
  maxHeight: '60vh',
  flex: 1,
  overflowY: 'auto',
  [media.md]: {
    maxHeight: '80vh',
  },
}));

export const NotificationDrawer: FC = () => {
  const { md } = useBreakpoint();
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const [pageIndex, setPageIndex] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { user } = useUser();
  const { notifications } = useNotification(pageIndex);

  const unreadNotificationsCount = user?.userSummary.unreadNotificationCount;
  const maxNotificationPage = notifications.meta.totalPages;

  const handleOpenDrawer = async () => {
    setPageIndex(1);
    setIsDrawerOpen(true);
    await UserApi.updateLastNotifiedAt();
  };

  const handleCloseDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <Button
        size={'small'}
        type={'link'}
        onClick={handleOpenDrawer}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          src={
            unreadNotificationsCount > 0
              ? '/images/ico_bells.svg'
              : '/images/ico_bell.svg'
          }
          width={24}
          height={24}
          alt="bell"
        />
      </Button>
      <Drawer
        closable
        maskClosable
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        placement={md ? 'right' : 'bottom'}
        headerStyle={{ display: 'none' }}
        style={{
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
        }}
        contentWrapperStyle={{
          height: 'auto',
        }}
        rootStyle={{
          bottom: 0,
        }}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Heading color={'primary'} level={4}>
          {t('Notifications') + ` (${unreadNotificationsCount ?? '0'})`}
        </Heading>
        <Divider />
        <StyledDiv>
          {notifications.meta.totalItems === 0 && (
            <Empty description={t('NoRecord')} />
          )}
          {notifications.meta.totalItems > 0 &&
            new Array(pageIndex)
              .fill(null)
              .map((_, index) => (
                <NotificationList pageIndex={index + 1} key={index + 1} />
              ))}
        </StyledDiv>
        <Divider />
        <Space
          full
          direction={'horizontal'}
          align={'center'}
          justify={'center'}
        >
          {pageIndex < maxNotificationPage && (
            <Button
              type={'primary'}
              onClick={() => setPageIndex(pageIndex + 1)}
            >
              {t('LoadMore')}
            </Button>
          )}
          <Button type={'primaryOutline'} onClick={handleCloseDrawer}>
            {t('Close')}
          </Button>
        </Space>
      </Drawer>
    </>
  );
};
