'use client';
import Image from 'next/legacy/image';
import { useEffect, useState } from 'react';
import ResponsiveModal from '../../common/responsive-modal';
import { Heading } from '../../common/typography';
import Divider from '../../common/divider';
import { ThemeSwitch } from './theme-switch';
import { LanguageDropdown } from './language-dropdown';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../../libs/i18n/common.config';
import Button from '../../common/Button';
import { Space } from '../../common/layout';

export const NavSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation(commonConfig.i18nNamespaces);

  /** useEffect to close the bottom drawer on mobile when we "touch-scroll" */
  useEffect(() => {
    const onTouchMove = () => setIsModalOpen(false);
    // clean up code
    window.removeEventListener('touchmove', onTouchMove);
    window.addEventListener('touchmove', onTouchMove, { passive: true });
    return () => window.removeEventListener('touchmove', onTouchMove);
  }, []);

  const handleOpeningModal = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <Button
        size={'small'}
        type={'link'}
        onClick={handleOpeningModal}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Image src="/images/settings_pink.svg" width={24} height={24} alt="" />
      </Button>

      <ResponsiveModal
        width={688}
        open={isModalOpen}
        closable={true}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Heading level={4} color="primary" weight={900}>
          {t('Settings')}
        </Heading>

        <Divider size="xl" />
        <Space direction="vertical" style={{ width: '100%' }}>
          <ThemeSwitch expanded />
          <Space
            direction="vertical"
            style={{ width: '100%', marginTop: '28px' }}
          >
            <LanguageDropdown expanded />
          </Space>
        </Space>
      </ResponsiveModal>
    </>
  );
};
