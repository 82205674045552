import { List } from 'antd';
import type { FC } from 'react';
import useNotification from '../../libs/hooks/useNotification';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import type { NotificationItem } from '@candypig-monorepo/types';
import { url } from '../../constant/url';
import { useRouter } from 'next/router';
import Text from '../common/typography/text';

type Props = {
  pageIndex: number;
};

const StyledList = styled(List)({
  whiteSpace: 'pre-line',
});

export const StyledListItem = styled(List.Item)(({ theme }) => ({
  ['&.ant-list-item']: {
    background: theme.token.colorBgContainer,
    padding: theme.token.paddingSM,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    cursor: 'pointer',
    transition: 'all 0.3s',
    ['&:hover']: {
      backgroundColor: theme.token.colorNotificationHover,
    },
  },
}));

export const NotificationList: FC<Props> = ({ pageIndex }) => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { notifications } = useNotification(pageIndex);
  const router = useRouter();

  const handleClick = async (event: string, entityId: string) => {
    if (event === 'LOTTERY_WON') {
      await router.push(url.nft_items);
    }
    if (event === 'LOTTERY_REFUND') {
      if (!entityId) {
        await router.push('/404');
      }
      await router.push({
        pathname: url.lottery_detail,
        query: { id: entityId, tab: 'history' },
      });
    }
  };

  return (
    <StyledList
      itemLayout="horizontal"
      dataSource={notifications.data}
      renderItem={(item: NotificationItem) => (
        <StyledListItem onClick={() => handleClick(item.event, item.entityId)}>
          <>
            {t('notification.' + item.event, {
              ...JSON.parse(item.metadata),
            })}
            <Text>
              {dayjs(new Date(item.datetime)).format('YYYY/MM/DD HH:mm')}
            </Text>
          </>
        </StyledListItem>
      )}
    />
  );
};
