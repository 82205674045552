import useSWR from 'swr';
import type { GetUserMeResponse } from '@candypig-monorepo/types';
import fetcher from '../api/utils/fetcher';

const useUser = () => {
  const { data, error } = useSWR('/user/me', fetcher);

  return {
    user: data as GetUserMeResponse,
    authenticated: !error && data && JSON.stringify(data) !== '{}',
    isLoading: !error && !data,
    isError: error,
  };
};

export default useUser;
