import type { FC } from 'react';
import Script from 'next/script';
import styled from '@emotion/styled';
import Lottie from 'react-lottie';
import * as animationData from '../../public/lotties/chatbot.json';
import { useRouter } from 'next/router';
import { Global } from '@emotion/react';
import { media } from '@candypig-monorepo/types';

const CSWrapper = styled.div({
  textAlign: 'center',
  position: 'fixed',
  width: 48,
  height: 48,
  right: 16,
  bottom: 100,
  zIndex: '999 !important',
  cursor: 'pointer',
  backgroundColor: '#ffe4ed',
  borderRadius: '16px',
  pointerEvents: 'none',
  [media.lg]: {
    bottom: 25,
    right: 24,
    width: 65,
    height: 65,
  },
});

export const CustomerServiceButton: FC = () => {
  const router = useRouter();
  const { locale: currentLanguage } = router;

  const codePlan =
    currentLanguage === 'zh-HK'
      ? process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_ZH_HK
      : process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_EN;

  return (
    <>
      <div
        id={`onlinehelp-button-${process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_ZH_HK}`}
        style={{
          display:
            codePlan !== process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_ZH_HK
              ? 'none'
              : 'block',
        }}
      ></div>
      <div
        id={`onlinehelp-button-${process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_EN}`}
        style={{
          display:
            codePlan !== process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_EN
              ? 'none'
              : 'block',
        }}
      ></div>
      <CSWrapper>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </CSWrapper>
      <Script
        id="chatjs-secondary"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var OnlineHelpAPI=OnlineHelpAPI||{chat_buttons:[]};OnlineHelpAPI.chat_buttons.push({code_plan:"${process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_ZH_HK}",div_id:"onlinehelp-button-${process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_ZH_HK}"});
        `,
        }}
      />
      <Script
        id="chatjs-main"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var OnlineHelpAPI=OnlineHelpAPI||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:"${process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_EN}",div_id:"onlinehelp-button-${process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_EN}"}),t.site_id=60003785,t.main_code_plan="${process.env.NEXT_PUBLIC_LIVEHELP100_CODEPLAN_EN}",e("https://vue.livehelp100service.com/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://vue.livehelp100servicestandby.com/livechat.ashx?siteId=")},5e3)})(OnlineHelpAPI||{})
        `,
        }}
      />

      <Global
        styles={{
          '#onlinehelp-button-1590a374-9a17-46ad-9511-58af6e0b1bfd iframe, #onlinehelp-button-92b392b9-af80-4383-b2bd-61ecb7d9f67a iframe':
            {
              zIndex: '1 !important',
              opacity: 0.01,
              right: '-16px !important',
              bottom: '70px !important',
              [media.md]: {
                right: '0 !important',
                bottom: '0 !important',
              },
            },

          '#onlinehelp-container iframe[title="在线聊天邀请"]': {
            display: 'none',
          },
          '#onlinehelp-container iframe[title="新消息"]': {
            display: 'none',
          },

          '#onlinehelp-container iframe[title="Chat Invitation"]': {
            display: 'none',
          },
          '#onlinehelp-container iframe[title="New Message"]': {
            display: 'none',
          },

          '#chat_button_adaptive_container': {
            opacity: 0.01,
            right: '-16px !important',
            bottom: '70px !important',
            zIndex: '1 !important',
            [media.md]: {
              right: '0 !important',
              bottom: '0 !important',
            },
          },
          '.chatButton': {
            display: 'none',
          },
          '#chat_window_container': {
            right: '0px !important',
            [media.sm]: {
              bottom: '100px !important',
            },
          },
          '.chatButtonContainer': {
            width: '48px !important',
            height: '48px !important',
          },
          '.online .chatButton:after': {
            right: '0px !important',
            bottom: '220px !important',
          },
          '.offline .chatButton:after': {
            right: '0px !important',
            bottom: '220px !important',
          },
        }}
      />
    </>
  );
};
