import type { FC } from 'react';
import Image from 'next/image';

const IconNoResult: FC = () => (
  <Image
    src={'/images/no-result-1.png'}
    alt={'no result'}
    width={120}
    height={116}
  />
);

export default IconNoResult;
