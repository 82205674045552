import type { FC } from 'react';
import type { EmptyProps as AntdEmptyProps } from 'antd';
import { Empty as AntdEmpty } from 'antd';
import styled from '@emotion/styled';
import { Text } from './typography';
import IconNoResult from './icon/icon-no-result';

type EmptyProps = AntdEmptyProps & {
  full?: boolean;
};

const StyledEmpty = styled(AntdEmpty, {
  shouldForwardProp: (propName) => propName !== 'full',
})<EmptyProps>((props) => ({
  width: props.full && '100%',
}));

const Empty: FC<EmptyProps> = ({ full, description }) => {
  return (
    <StyledEmpty
      image={<IconNoResult />}
      imageStyle={{ height: 'auto', marginBottom: 8 }}
      full={full}
      description={<Text>{description}</Text>}
    />
  );
};

export default Empty;
