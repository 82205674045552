import type { ButtonProps as AntdButtonProps } from 'antd';
import { Button as AntdButton } from 'antd';
import type { Property } from 'csstype';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';
import type { Theme } from '@emotion/react';
import useAuth from '../../libs/hooks/useAuth';
import { useRouter } from 'next/router';
import type { MouseEvent } from 'react';
import { url } from '../../constant/url';

export type ButtonProps = Omit<AntdButtonProps, 'type'> & {
  type:
    | AntdButtonProps['type']
    | 'primaryOutline'
    | 'secondary'
    | 'secondaryOutline';
  margin?: Property.Margin;
  full?: boolean;
  requireAuth?: boolean;
  responsive?: boolean;
  stopPropagation?: boolean;
  smallSize?: boolean;
};

const filterProps = (propName) =>
  !['margin', 'responsive', 'full', 'smallSize'].includes(propName);

const overwriteThemingStyle = ({
  theme,
  margin,
  full,
  responsive,
  smallSize,
}: {
  theme: Theme;
  margin?: Property.Margin;
  full?: boolean;
  responsive?: boolean;
  smallSize?: boolean;
}) => ({
  ['&.ant-btn']: {
    fontFamily: '"NunitoVariable", sans-serif', //No token on theme
    margin: margin && margin,
    padding: smallSize
      ? `${theme.token.sizeXXS}px ${theme.token.sizeXXS}px`
      : responsive
      ? `${theme.token.sizeXS}px ${theme.token.sizeSM}px`
      : `${theme.token.sizeSM}px ${theme.token.sizeLG}px`,
    height: responsive ? 38 : 48,
    borderRadius: 60,
    fontWeight: theme.token.fontWeightStrong,
    fontSize: responsive ? theme.token.fontSizeXS : theme.token.fontSizeSM,
    minWidth: 36,
    boxShadow: 'none',
    ...(full && { width: '100%' }),
    ['&:focus-visible']: {
      outline: 'none',
    },
    [media.lg]: {
      padding: `${theme.token.sizeSM}px ${theme.token.sizeLG}px`,
      fontSize: theme.token.fontSize,
      height: 48,
    },
  },
  ['&.ant-btn-sm']: {
    fontSize: theme.token.fontSizeXS,
    padding: `${theme.token.sizeXS}px ${theme.token.sizeSM}px`,
    [media.sm]: {
      fontSize: theme.token.fontSizeSM,
    },
  },
});

const PrimaryButton = styled(AntdButton, {
  shouldForwardProp: filterProps,
})<ButtonProps>((props) => ({
  ...overwriteThemingStyle(props),
  ['&.ant-btn:disabled']: {
    backgroundColor: props.theme.token.colorBorder,
  },
}));

const SecondaryButton = styled(AntdButton, {
  shouldForwardProp: filterProps,
})<ButtonProps>((props) => ({
  ...overwriteThemingStyle(props),
  backgroundColor: props.theme.token.colorInfo,
  borderColor: props.theme.token.colorInfo,
  ['&.ant-btn:not(:disabled):hover']: {
    backgroundColor: props.theme.token.colorInfoHover,
  },
}));

const PrimaryOutlineButton = styled(AntdButton, {
  shouldForwardProp: filterProps,
})<ButtonProps>((props) => ({
  ...overwriteThemingStyle(props),
  borderColor: props.theme.token.colorPrimary,
  color: props.theme.token.colorPrimary,
  ['&.ant-btn:not(:disabled):hover']: {
    borderColor: props.theme.token.colorPrimaryHover,
    color: props.theme.token.colorPrimaryHover,
  },
  ['&.ant-btn:disabled']: {
    backgroundColor: props.theme.token.colorPrimaryButtonBg,
  },
}));

const SecondaryOutlineButton = styled(AntdButton, {
  shouldForwardProp: filterProps,
})<ButtonProps>((props) => ({
  ...overwriteThemingStyle(props),
  color: props.theme.token.colorInfo,
  borderColor: props.theme.token.colorInfo,
  ['&.ant-btn:not(:disabled):hover']: {
    borderColor: props.theme.token.colorInfoHover,
    color: props.theme.token.colorInfoHover,
  },
  ['&.ant-btn:disabled']: {
    backgroundColor: props.theme.token.colorPrimaryButtonBg,
  },
}));

const LinkButton = styled(AntdButton, {
  shouldForwardProp: filterProps,
})<ButtonProps>((props) => ({
  ...overwriteThemingStyle(props),
  color: props.theme.token.colorPrimary,
  fontWeight: 900,
  fontVariationSettings: '"wght" 900',
  ['&.ant-btn-link']: {
    padding: `${props.theme.token.sizeSM}px ${props.theme.token.sizeXXS}px`,
  },
}));

const TextButton = styled(AntdButton, {
  shouldForwardProp: filterProps,
})<ButtonProps>((props) => ({
  ...overwriteThemingStyle(props),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ['&.ant-btn']: {
    padding: 0,
  },
  ['&.ant-btn:not(:disabled):hover']: {
    backgroundColor: 'transparent',
  },
}));

export const Button = ({
  type,
  onClick,
  htmlType,
  disabled,
  loading,
  margin,
  size = 'middle',
  children,
  style,
  requireAuth,
  stopPropagation = true,
  ...props
}: ButtonProps) => {
  const { authenticated } = useAuth();
  const router = useRouter();

  let buttonType: AntdButtonProps['type'];
  let StyledButton;

  switch (type) {
    case 'primary':
      buttonType = 'primary';
      StyledButton = PrimaryButton;
      break;
    case 'secondary':
      buttonType = 'primary';
      StyledButton = SecondaryButton;
      break;
    case 'primaryOutline':
      buttonType = 'default';
      StyledButton = PrimaryOutlineButton;
      break;
    case 'secondaryOutline':
      buttonType = 'default';
      StyledButton = SecondaryOutlineButton;
      break;
    case 'link':
      buttonType = 'link';
      StyledButton = LinkButton;
      break;
    case 'text':
      buttonType = 'text';
      StyledButton = TextButton;
      break;
    default:
      StyledButton = PrimaryButton;
  }

  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    stopPropagation && event.stopPropagation();
    if (requireAuth && !authenticated) {
      router.push(url.login);
    } else {
      onClick && onClick(event);
    }
  };

  return (
    <StyledButton
      margin={margin}
      size={size}
      onClick={handleClick}
      htmlType={htmlType}
      disabled={disabled}
      loading={loading}
      type={buttonType}
      style={style}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
