import styled from '@emotion/styled';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { setCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import Image from 'next/legacy/image';
import { Text } from '../../common/typography';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../../libs/i18n/common.config';
import { Space } from '../../common/layout';
import Button from '../../common/Button';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import { LANGUAGE_LIST } from '../../../constant/language-list';
import { media } from '@candypig-monorepo/types';

type Props = {
  isMobileMenu?: boolean;
  expanded?: boolean;
  onMenuClick?: () => void;
};

const LanguageMenuItem = styled.div(({ theme }) => ({
  background: 'transparent',
  fontWeight: 900,
  fontSize: theme.token.fontSizeSM,
  [media.md]: {
    fontSize: theme.token.fontSize,
  },
}));

const MenuCtn = styled.div(({ theme }) => ({
  ['.ant-dropdown-menu-root']: {
    backgroundColor: theme.token.colorBgLanguageDropDown,
  },
  ['& .ant-dropdown-menu']: {
    padding: theme.token.sizeSM,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ['& .ant-dropdown-menu .ant-dropdown-menu-item']: {
    width: '100%',
    textAlign: 'center',
    padding: `${theme.token.sizeXS}px ${theme.token.sizeMD}px`,
    color: theme.token.colorTextDisabled,
    ['&:hover, &.ant-dropdown-menu-item-selected']: {
      backgroundColor: 'rgba(219, 219, 219, 0.40)',
      ['a']: {
        color: theme.token.colorPrimary,
      },
    },
  },
}));

const TriggerBox = styled.div(({ theme }) => ({
  width: theme.token.fontSizeIcon,
  height: theme.token.fontSizeIcon,
  display: 'flex',
  [media.md]: {
    width: theme.token.fontSizeIcon,
    height: theme.token.fontSizeIcon,
  },
}));

export const LanguageDropdown: FC<Props> = ({
  onMenuClick,
  expanded = false,
}: Props) => {
  const router = useRouter();
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { pathname, query, locale: currentLocale } = router;

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    setCookie('NEXT_LOCALE', key);
    onMenuClick && onMenuClick();
  };

  const langItems = Object.entries(LANGUAGE_LIST).map(([locale, title]) => ({
    key: locale,
    id: locale,
    label: (
      <Link
        href={{ pathname: pathname, query: query }}
        locale={locale}
        className={currentLocale === locale ? 'active' : ''}
      >
        <LanguageMenuItem>{title}</LanguageMenuItem>
      </Link>
    ),
  }));

  return (
    <>
      {expanded && (
        <Space full size={'lg'} justify={'space-between'}>
          <Text>{t('Language')}</Text>
          <Dropdown
            className="lang-menu"
            menu={{
              items: langItems,
              onClick: handleMenuClick,
              selectedKeys: [currentLocale],
            }}
            dropdownRender={(menus) => <MenuCtn>{menus}</MenuCtn>}
            placement="bottom"
            trigger={['click']}
          >
            <Button size={'small'} type={'default'} style={{ width: '152px' }}>
              <Space>
                {LANGUAGE_LIST[currentLocale]}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      )}
      {!expanded && (
        <Dropdown
          className="lang-menu"
          menu={{
            items: langItems,
            onClick: handleMenuClick,
            selectedKeys: [currentLocale],
          }}
          dropdownRender={(menus) => <MenuCtn>{menus}</MenuCtn>}
          placement="bottom"
          trigger={['click']}
        >
          <TriggerBox>
            <Image
              src={'/images/ico_lang.svg'}
              width={24}
              height={24}
              alt={'Language'}
            />
          </TriggerBox>
        </Dropdown>
      )}
    </>
  );
};
