import type { FC } from 'react';
import { Col, Row } from '../common/layout';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../libs/i18n/common.config';
import type { CSSInterpolation } from '@emotion/serialize/types';
import { Heading, Link, Paragraph } from '../common/typography';
import { Layout } from 'antd';
import { Space } from '../../components/common/layout';
import { media } from '@candypig-monorepo/types';
import { gitbookUrl } from '../../constant/url';
import { IconFacebook, IconInstagram, IconTwitter, Logo } from '../common/icon';

type Props = {
  style?: CSSInterpolation;
  withPigImg?: boolean;
};
const LinkWrapper = styled.div(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  padding: `${theme.token.sizeXL}px ${theme.token.sizeMD}px`,
  [media.sm]: {
    padding: `${theme.token.sizeXL}px ${theme.token.sizeLG}px`,
  },
  [media.md]: {
    padding: `${theme.token.sizeXL}px ${theme.token.sizeLG}px`,
  },
  [media.xl]: {
    minWidth: 1200,
    margin: 'auto',
    padding: `${theme.token.sizeXXL}px ${theme.token.sizeLG}px`,
  },
}));

const SnsImg = styled.span`
  padding-left: 16px;
`;

const StyledHeading = styled(Heading)(({ theme }) => ({
  textTransform: 'uppercase',
  ['&&.ant-typography']: {
    fontWeight: 900,
    fontVariationSettings: '"wght" 900',
    fontSize: theme.token.fontSizeLG,
  },
}));

const StyledFooterWrapper = styled.div(({ theme }) => ({
  clipPath: 'circle(295vw at 50% 296vw)',
  background: theme.token.colorBgFooter,
  paddingTop: 80,
  [media.md]: {
    paddingTop: 120,
  },
}));

export const StyledCol = styled(Col)({
  [media.sm_max]: {
    textAlign: 'center',
  },
});

const StyledSpan = styled.span(({ theme }) => ({
  color: theme.token.colorFooterText,
}));

const Section = ({ title, links }) => (
  <Col
    lg={{ span: 6, offset: 0 }}
    md={{ span: 6, offset: 0 }}
    sm={{ flex: 10 }}
    xs={{ span: 20, offset: 4 }}
  >
    <Space direction="vertical" size="middle">
      <StyledHeading color={'primary'} level={5}>
        {title}
      </StyledHeading>
      {links.map((link, index) => (
        <Link key={index} href={link.href} target={'_blank'}>
          <StyledSpan>{link.label}</StyledSpan>
        </Link>
      ))}
    </Space>
  </Col>
);

export const CustomFooter: FC<Props> = () => {
  const { t, i18n } = useTranslation(commonConfig.i18nNamespaces);

  const sections = [
    {
      title: t('footer.About'),
      links: [
        {
          label: t('footer.AboutUs'),
          href: gitbookUrl.aboutUs[i18n.language],
        },
        {
          label: t('footer.Transparency'),
          href: gitbookUrl.transparency[i18n.language],
        },
      ],
    },
    {
      title: t('footer.Help'),
      links: [
        {
          label: t('footer.Guides'),
          href: gitbookUrl.guide[i18n.language],
        },
      ],
    },
    {
      title: t('footer.Legal'),
      links: [
        {
          label: t('footer.PrivacyPolicy'),
          href: gitbookUrl.privacyPolicy[i18n.language],
        },
        {
          label: t('footer.TermsAndConditions'),
          href: gitbookUrl.termsAndConditions[i18n.language],
        },
        {
          label: t('footer.Disclaimer'),
          href: gitbookUrl.disclaimer[i18n.language],
        },
      ],
    },
  ];

  return (
    <StyledFooterWrapper>
      <Layout.Footer style={{ padding: 0 }}>
        <LinkWrapper>
          <Space
            direction="vertical"
            size="large"
            style={{ width: '100%', padding: '8px' }}
          >
            <Row justify={'space-between'} gutterSize={['xl', 'xl']}>
              <StyledCol xs={24} sm={{ flex: 10 }} md={24 / 4} lg={24 / 4}>
                <Logo />
              </StyledCol>
              {sections.map((section, index) => (
                <Section
                  key={index}
                  title={section.title}
                  links={section.links}
                />
              ))}
            </Row>
            <Row
              align={'middle'}
              justify={'space-between'}
              gutterSize={['xxs', 'xxs']}
            >
              <Col
                lg={{ span: 8, order: 2, offset: 0 }}
                md={{ span: 12, order: 2, offset: 0 }}
                xs={{ span: 20, order: 2, offset: 4 }}
              >
                <Heading
                  color={'primary'}
                  size={'lg'}
                  style={{ display: 'inline-block' }}
                  level={5}
                  margin={0}
                >
                  {t('footer.followUs')}
                </Heading>
                <SnsImg>
                  <IconTwitter />
                </SnsImg>
                <SnsImg>
                  <IconInstagram />
                </SnsImg>
                <SnsImg>
                  <IconFacebook />
                </SnsImg>
              </Col>
              <Col
                lg={{ span: 8, offset: 0 }}
                md={{ span: 12, order: 3, offset: 0 }}
                xs={{ span: 20, order: 3, offset: 4 }}
              >
                <Paragraph size={'sm'} align={'start'} md={{ align: 'center' }}>
                  {`©${new Date().getFullYear()} CandyPig. Inc.`}
                </Paragraph>
              </Col>
            </Row>
          </Space>
        </LinkWrapper>
      </Layout.Footer>
    </StyledFooterWrapper>
  );
};
