import type { ThemeColor } from '../typography/useColor';
import { useColor } from '../typography/useColor';

type Props = {
  size?: number;
  color?: ThemeColor;
};

const IconLending = ({ size = 14, color = 'textDisabled' }: Props) => {
  const svgColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M23.2756 17.8082C23.1298 17.5371 22.9124 17.2967 22.6413 17.1049L22.5211 17.023C22.2347 16.844 21.8945 16.7187 21.539 16.6598C21.1861 16.6036 20.8178 16.6138 20.4776 16.688L16.5953 17.4118C16.6387 17.1944 16.6464 16.9719 16.6132 16.7519C16.5569 16.3836 16.3907 16.0307 16.1349 15.7289C15.8817 15.4322 15.5441 15.1893 15.1528 15.0256C14.7692 14.8619 14.337 14.7749 13.9047 14.7749H9.13235C8.73082 14.7749 8.33696 14.8389 7.96611 14.9642C7.5876 15.0921 7.25 15.2762 6.961 15.5115L4.82289 17.2455H2.10166C1.68734 17.2455 1.29604 17.3785 0.999361 17.6189C0.689898 17.8696 0.513428 18.2199 0.513428 18.5806V21.665C0.513428 22.0256 0.689898 22.3785 0.999361 22.6266C1.29604 22.867 1.68734 23 2.10166 23H11.9994C12.0633 23 12.1272 22.9923 12.1886 22.9821L18.2807 21.7468C18.3191 21.7391 18.3574 21.7289 18.3958 21.7161L22.094 20.4399L22.1349 20.4246C22.4981 20.2762 22.8152 20.0537 23.048 19.7801C23.2858 19.4987 23.4341 19.1662 23.4751 18.8235C23.516 18.4783 23.4469 18.1279 23.2756 17.8107V17.8082ZM14.6848 17.601C14.4802 17.7647 14.1963 17.8593 13.9047 17.8593H11.2398C11.0275 17.8593 10.828 17.9284 10.6746 18.0511C10.5083 18.1867 10.4111 18.3785 10.4111 18.578C10.4111 18.7775 10.5058 18.9693 10.672 19.1049C10.8254 19.2302 11.0249 19.2967 11.2372 19.2967H14.2832C14.3421 19.2967 14.4009 19.2916 14.4572 19.2813L20.8382 18.0921L20.8638 18.087C21.0531 18.0435 21.2551 18.0614 21.429 18.133C21.5927 18.1995 21.718 18.3095 21.782 18.4399C21.8408 18.5601 21.8408 18.6854 21.7871 18.8056C21.7257 18.9361 21.6029 19.046 21.4572 19.11L17.8382 20.3606L11.9022 21.5652H5.97379V18.2788L8.08888 16.5652C8.22443 16.4552 8.38555 16.3683 8.56458 16.3069C8.74616 16.2455 8.93798 16.2148 9.13491 16.2148H13.9047C14.1988 16.2148 14.4827 16.3095 14.6848 16.4731C14.8766 16.6266 14.9815 16.8286 14.9815 17.0384C14.9815 17.2481 14.8766 17.4476 14.6873 17.6036L14.6848 17.601ZM4.32161 18.6803V21.5627H2.16816V18.6803H4.32161Z"
          fill={svgColor}
        />
        <path
          d="M10.1324 9.32481C10.452 9.60614 10.8613 9.78005 11.3012 9.8312V10.844C11.1426 10.7852 11.0147 10.6752 10.961 10.5371C10.9303 10.4527 10.8817 10.3785 10.8178 10.312C10.7564 10.2481 10.6822 10.1969 10.5978 10.1611C10.516 10.1253 10.429 10.1049 10.3395 10.0997C10.25 10.0946 10.1605 10.1049 10.0761 10.133C9.98913 10.1611 9.90985 10.202 9.84079 10.2558C9.76918 10.3146 9.71036 10.3836 9.66944 10.4604C9.62596 10.5422 9.60294 10.6292 9.60038 10.7212C9.59783 10.8133 9.61317 10.9003 9.64898 10.9795C9.7922 11.335 10.0531 11.6368 10.4009 11.8542C10.672 12.023 10.9815 12.1279 11.3037 12.1637V12.5678H12.7027V12.1637C13.1426 12.1151 13.5492 11.9386 13.8715 11.6598C14.2577 11.3223 14.4776 10.8517 14.4776 10.3683C14.4776 9.88491 14.2551 9.41432 13.8715 9.07673C13.5518 8.7954 13.1426 8.62148 12.7027 8.57289V7.5601C12.7462 7.57545 12.7871 7.59591 12.8254 7.61893C12.9303 7.68286 13.007 7.77238 13.048 7.87212C13.1119 8.03325 13.2423 8.16113 13.4137 8.23274C13.4955 8.26599 13.5799 8.28644 13.6694 8.289C13.7564 8.29412 13.8459 8.28133 13.9277 8.25576C14.0121 8.23018 14.0914 8.18926 14.1605 8.13555C14.2321 8.07928 14.2884 8.01279 14.3293 7.93606C14.3728 7.85678 14.3983 7.76982 14.4035 7.68286C14.4086 7.59335 14.3958 7.50384 14.36 7.42199C14.2168 7.0665 13.9559 6.76471 13.6081 6.54731C13.337 6.37852 13.0275 6.27366 12.7052 6.23785V5.83376H11.3063V6.23785C10.8664 6.28644 10.4597 6.46292 10.1375 6.74169C9.75128 7.07928 9.53133 7.54987 9.53133 8.03325C9.53133 8.51662 9.75128 8.98721 10.1375 9.32481H10.1324ZM12.9022 10.0077C13.0147 10.1049 13.0735 10.2327 13.0735 10.3657C13.0735 10.4987 13.0121 10.6266 12.9022 10.7238C12.8434 10.7749 12.7743 10.8159 12.7001 10.844V9.89003C12.7769 9.91816 12.8459 9.95908 12.9022 10.0102V10.0077ZM11.3012 8.50895C11.2244 8.48082 11.1554 8.4399 11.0991 8.38875C10.9866 8.29156 10.9252 8.16368 10.9252 8.03069C10.9252 7.8977 10.9866 7.76982 11.0991 7.67263C11.1579 7.62148 11.227 7.58056 11.3012 7.55243V8.50895Z"
          fill={svgColor}
        />
        <path
          d="M4.60038 11.4604C5.39067 13.4859 7.88683 14.5115 12.0173 14.5115C14.8485 14.5115 18.429 13.977 19.4035 11.4297C20.0659 9.69565 19.3676 6.56777 16.2986 3.72634L17.9712 1.58312L17.718 1.43478C17.5671 1.34783 17.3063 1.20716 16.9124 1.03581L16.851 1.01023C15.9636 0.631714 14.1554 0 12.0147 0C9.75128 0 7.81266 0.726343 7.08376 1.04092C6.97123 1.08951 6.86893 1.13555 6.77174 1.18159C6.59271 1.26598 6.42647 1.35038 6.27813 1.43478L6.02238 1.58056L7.68734 3.7289C5.51087 5.81074 3.65409 9.05882 4.59527 11.4629L4.60038 11.4604ZM11.8306 1.73146C11.1119 1.91304 10.2295 1.92327 9.20652 1.75703C9.1477 1.7468 9.09143 1.73657 9.03261 1.72634C9.81778 1.50128 10.874 1.28389 12.0173 1.28389C12.4648 1.28389 12.9201 1.31714 13.383 1.38107C12.8613 1.48338 12.3421 1.59847 11.8306 1.7289V1.73146ZM14.7462 3.50384C13.0147 4.02558 10.9687 4.02558 9.23977 3.50384L8.85614 3.01023C8.89194 3.01535 8.92775 3.02302 8.96356 3.02813C10.1912 3.22506 11.2807 3.20972 12.2014 2.97442C13.4009 2.67008 14.6004 2.45269 15.6464 2.34527L14.7436 3.50384H14.7462ZM8.6413 4.67775C10.7538 5.3555 13.2398 5.3555 15.3523 4.67775C16.1758 5.46036 16.8664 6.3376 17.406 7.29156C18.0045 8.36317 18.296 9.37596 18.2474 10.2225C18.1963 11.0921 17.7896 11.7519 17.0019 12.2404C15.9482 12.8926 14.2705 13.2251 12.0173 13.2251C9.76407 13.2251 8.07353 12.8977 7.01215 12.2558C6.21931 11.7749 5.80755 11.1228 5.75128 10.266C5.69757 9.42967 5.98146 8.42199 6.57737 7.34527C7.08887 6.41944 7.81778 5.48082 8.63875 4.68286L8.6413 4.67775Z"
          fill={svgColor}
        />
      </g>
    </svg>
  );
};

export default IconLending;
