import type { FC } from 'react';
import { Space } from '../../../../common/layout';
import { url } from '../../../../../constant/url';
import { Link } from '../../../../common/typography';
import { MENU_ITEMS, useMenuItemsBasedOnAuth } from '../../const';
import Logo from '../../../../common/icon/logo';
import styled from '@emotion/styled';
import { media } from '@candypig-monorepo/types';

// --- Keeping this in case we need this back
// const StyledIconLogo = styled('div', {
//   shouldForwardProp: (propName) => !['authenticated'].includes(propName),
// })<{ authenticated: boolean }>((props) => ({
//   display: props.authenticated ? 'flex' : 'none',
//   width: 32,
//   height: 24,
//   cursor: 'pointer',
//   position: 'relative',
//   [media.sm]: {
//     display: 'none',
//   },
// }));

const StyledLogo = styled('div', {
  shouldForwardProp: (propName) => !['authenticated'].includes(propName),
})(() => ({
  width: 100,
  height: 24,
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  [media.sm]: {
    display: 'flex',
    width: 120,
  },
}));

export const MobileTopMenu: FC = () => {
  const items = useMenuItemsBasedOnAuth([
    MENU_ITEMS.login,
    MENU_ITEMS.register,
    MENU_ITEMS.dailyProfitLose,
    MENU_ITEMS.notification,
    MENU_ITEMS.settings,
  ]);

  return (
    <>
      <Space full justify={'space-between'} align={'center'}>
        <Link href={url.landing}>
          <div style={{ lineHeight: 1 }}>
            <StyledLogo>
              <Logo fill />
            </StyledLogo>
          </div>
        </Link>
        <Space size={'sm'} justify={'flex-end'}>
          {items.map((item) => {
            const Comp = item.component;
            return <Comp key={item.tKey} item={item} />;
          })}
        </Space>
      </Space>
    </>
  );
};
