import type { JSX } from 'react';
import { useMemo } from 'react';
import { url } from '../../../constant/url';
import IconUser from '../../common/icon/icon-user';
import IconNftImage from '../../common/icon/icon-nft-image';
import IconCandy from '../../common/icon/icon-candy';
import IconLending from '../../common/icon/icon-lending';
import IconList from '../../common/icon/icon-list';
import IconCasino from '../../common/icon/icon-casino';
import IconWallet from '../../common/icon/icon-wallet';
import IconSetting from '../../common/icon/icon-settings';
import { LanguageDropdown } from './language-dropdown';
import { ThemeSwitch } from './theme-switch';
import MenuButton from './menu-button';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { UserType } from '@candypig-monorepo/types';
import useUser from '../../../libs/hooks/useUser';
import IconLogout from '../../common/icon/icon-logout';
import type { ThemeColor } from '../../common/typography/useColor';
import { NavSettings } from './nav-settings';
import DailyProfitLoseSummaryButton from './daily-profit-lose-summary-button';
import { NotificationDrawer } from '../../notification/notification-drawer';

export type MenuItem = Partial<ItemType> & {
  tKey: string;
  type?: string;
  url?: typeof url[keyof typeof url];
  showToGuestUserOnly?: boolean;
  showToAuthUserOnly?: boolean;
  showToBusinessUserOnly?: boolean;
  component?: (item: any) => JSX.Element;
  icon?: (props: { size?: number; color?: ThemeColor }) => JSX.Element;
  iconActive?: (props: { size?: number; color?: ThemeColor }) => JSX.Element;
  children?: MenuItem[];
};

export const MENU_ITEMS = {
  my_profile: {
    tKey: 'MyProfile',
    url: url.my_profile,
    icon: IconUser,
    showToAuthUserOnly: true,
  },
  marketplace: {
    tKey: 'nftMarkets',
    url: url.marketplace,
    icon: IconNftImage,
  },
  gacha_market: {
    tKey: 'nft',
    url: url.gacha_market,
    icon: IconNftImage,
  },
  candyland: {
    tKey: 'game',
    url: url.candyland,
    icon: IconCandy,
  },
  lending: {
    tKey: 'lending',
    url: url.lending,
    icon: IconLending,
  },
  nft_items: {
    tKey: 'NftItems',
    url: url.nft_items,
    icon: IconNftImage,
  },
  nft_records: {
    tKey: 'NftRecords',
    url: url.nft_records,
    icon: IconList,
  },
  candyland_record: {
    tKey: 'CandyLandRecord',
    url: url.candyland_record,
    icon: IconCasino,
  },
  deposit: {
    tKey: 'Deposit',
    url: url.deposit,
    icon: IconWallet,
  },
  withdrawal: {
    tKey: 'Withdrawal',
    url: url.withdrawal,
    icon: IconWallet,
  },
  transaction_history: {
    tKey: 'TransactionHistory',
    url: url.transaction_history,
    icon: IconWallet,
  },
  preference: {
    tKey: 'Preference',
    url: url.preference,
    icon: IconSetting,
  },
  dailyProfitLose: {
    tKey: 'dailyProfitLose',
    showToAuthUserOnly: true,
    component: DailyProfitLoseSummaryButton,
  },
  dashboard: {
    tKey: 'dashboard',
    url: url.my_profile,
    showToAuthUserOnly: true,
    component: MenuButton,
  },
  login: {
    tKey: 'login',
    url: url.login,
    showToGuestUserOnly: true,
    component: MenuButton,
  },
  logout: {
    tKey: 'logout',
    showToAuthUserOnly: true,
    icon: IconLogout,
  },
  register: {
    tKey: 'register',
    url: url.register,
    showToGuestUserOnly: true,
    component: MenuButton,
  },
  notification: {
    tKey: 'notification',
    showToAuthUserOnly: true,
    component: NotificationDrawer,
  },
  language: {
    tKey: 'language',
    component: LanguageDropdown,
  },
  theme: {
    tKey: 'theme',
    component: ThemeSwitch,
  },
  settings: {
    tKey: 'settings',
    component: NavSettings,
  },
};

export const useMenuItemsBasedOnAuth = (items: MenuItem[]) => {
  const { authenticated, user, isLoading } = useUser();

  const list = useMemo(
    () =>
      isLoading
        ? []
        : items
            .filter((item) =>
              item.showToBusinessUserOnly
                ? user?.user.type === UserType.BUSINESS
                : item.showToAuthUserOnly
                ? authenticated
                : item.showToGuestUserOnly
                ? !authenticated
                : true
            )
            .map((item) => {
              if (item.children) {
                item.children = item.children.filter((child) =>
                  child.showToBusinessUserOnly
                    ? user?.user.type === UserType.BUSINESS
                    : true
                );
              }
              return item;
            }),
    [authenticated, isLoading, items, user?.user.type]
  );

  return list;
};
