import IconUSDT from './icon-usdt';
import IconPigCoin from './icon-pigCoin';
import IconCheveron from './icon-cheveron';
import IconTwitter from './icon-twitter';
import IconFacebook from './icon-facebook';
import IconInstagram from './icon-instagram';
import Logo from './logo';
export {
  IconUSDT,
  IconPigCoin,
  IconCheveron,
  IconTwitter,
  IconFacebook,
  IconInstagram,
  Logo,
};
