import Image from 'next/image';
import { useThemeMode } from '../../../libs/hooks/useThemeMode';
import { themeMode } from '../../../constant/themes';
type Props = {
  size?: number;
  mode: 'up' | 'down';
};

const IconCheveron = ({ size = 14, mode = 'up' }: Props) => {
  const { selectedThemeMode } = useThemeMode();
  return (
    <Image
      src={`/images/cheveron_${mode}${
        selectedThemeMode === themeMode.light ? '' : '_white'
      }.svg`}
      width={size}
      height={size}
      alt="ico_cheveron"
    />
  );
};

export default IconCheveron;
