import type { User, UserResponse } from '@candypig-monorepo/types';
import { apiClient } from './api';

const UserApi = {
  async verifyEmail(token: string): Promise<UserResponse> {
    const { data } = await apiClient().post(
      '/user/verify-email',
      JSON.stringify({ token })
    );
    return data;
  },
  async updateLastNotifiedAt(): Promise<void> {
    const response = await apiClient().put('/user/me/last-notified-at');
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
  async changePassword(
    currentPassword: string,
    password: string
  ): Promise<string> {
    const { data } = await apiClient().post(
      '/user/change-password',
      JSON.stringify({ currentPassword, password })
    );
    return data;
  },
  async resetPassword(password: string): Promise<UserResponse> {
    const { data } = await apiClient().post(
      '/user/reset-password',
      JSON.stringify({ password })
    );
    return data;
  },
  async forgotPassword(email: string, token: string): Promise<UserResponse> {
    const { data } = await apiClient().post(
      '/user/forgot-password',
      JSON.stringify({ email }),
      {
        headers: {
          recaptcha: token,
        },
      }
    );
    return data;
  },
  async updatePreferredLanguage(
    preferredLanguage: string
  ): Promise<UserResponse> {
    const { data } = await apiClient().post(
      '/user/preferred-language',
      JSON.stringify({ preferredLanguage })
    );
    return data;
  },
  async disableNotificationChannel(notificationChannel: string): Promise<User> {
    const { data } = await apiClient().post(
      '/user/me/notification-channel/disable',
      JSON.stringify({
        notificationChannel,
      })
    );
    return data;
  },
  async stopAutoEntry(): Promise<UserResponse> {
    const { data } = await apiClient().post('/user/stop-auto-entry');
    return data;
  },
};

export default UserApi;
