import type { ThemeColor } from '../typography/useColor';
import { useColor } from '../typography/useColor';

type Props = {
  size?: number;
  color?: ThemeColor;
};

const IconCasino = ({ size = 14, color = 'white' }: Props) => {
  const svgColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="casino">
        <path
          id="Vector"
          d="M15.9762 8.36196L14.4083 1.28355C14.3507 1.02475 14.1927 0.79938 13.9691 0.656884C13.7455 0.514388 13.4746 0.466404 13.2156 0.523458L6.13728 2.0914C6.00906 2.1198 5.88769 2.17318 5.78009 2.24849C5.6725 2.3238 5.5808 2.41956 5.51022 2.53031C5.43964 2.64107 5.39157 2.76464 5.36875 2.89397C5.34593 3.0233 5.34881 3.15586 5.37722 3.28408L6.03412 6.24971H2C1.73487 6.25001 1.48069 6.35546 1.29322 6.54293C1.10575 6.7304 1.0003 6.98458 1 7.24971V14.4997C1.0003 14.7648 1.10575 15.019 1.29322 15.2065C1.48069 15.394 1.73487 15.4994 2 15.4997H9.25C9.51513 15.4994 9.76931 15.394 9.95678 15.2065C10.1442 15.019 10.2497 14.7648 10.25 14.4997V10.6546L15.2162 9.55461C15.475 9.49698 15.7003 9.33904 15.8428 9.11545C15.9853 8.89186 16.0333 8.62088 15.9762 8.36196ZM9.25 14.4997H2V7.24971H6.25566L6.94513 10.3624C6.99437 10.5843 7.11768 10.7829 7.29477 10.9254C7.47186 11.0679 7.69219 11.1459 7.9195 11.1465C7.99291 11.1465 8.06609 11.1384 8.13775 11.1225L9.25025 10.8761L9.25062 14.4997H9.25ZM15 8.57824L7.92147 10.1461L6.35356 3.06771L13.432 1.49971L15.0005 8.57799L15 8.57824Z"
          fill={svgColor}
        />
        <path
          id="Vector_2"
          d="M3 8.25045H4.25V9.50045H3V8.25045ZM3 12.2505H4.25V13.5005H3V12.2505ZM7 12.2505H8.25V13.5005H7V12.2505ZM5 10.2505H6.25V11.5005H5V10.2505ZM7.5455 3.83067L8.76594 3.56055L9.03606 4.78102L7.81563 5.05114L7.5455 3.83067ZM12.3165 6.86889L13.5369 6.59858L13.8073 7.81902L12.5868 8.08933L12.3165 6.86889ZM9.93137 5.34861L11.1518 5.0783L11.4221 6.2987L10.2017 6.56902L9.93137 5.34861Z"
          fill={svgColor}
        />
      </g>
    </svg>
  );
};

export default IconCasino;
