import { Switch } from 'antd';
import Image from 'next/image';
import { themeMode } from '../../../constant/themes';
import { Space } from '../../common/layout';
import { Text } from '../../common/typography';
import { useTranslation } from 'next-i18next';
import { commonConfig } from '../../../libs/i18n/common.config';
import { useThemeMode } from '../../../libs/hooks/useThemeMode';
import type { FC } from 'react';

type Props = {
  expanded?: boolean;
};

export const ThemeSwitch: FC<Props> = ({ expanded }) => {
  const { selectedThemeMode, setCurrentThemeAndSave } = useThemeMode();
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const isLight = selectedThemeMode === themeMode.light;

  return (
    <Space full size={'lg'} justify={'space-between'}>
      {expanded && <Text color={'default'}>{t('DarkMode')}</Text>}
      <Switch
        onClick={() => {
          setCurrentThemeAndSave(isLight ? themeMode.dark : themeMode.light);
        }}
        checked={selectedThemeMode === themeMode.dark}
        checkedChildren={
          <Image src="/images/ico_sun.svg" width={16} height={16} alt="" />
        }
        unCheckedChildren={
          <Image src="/images/ico_moon.svg" width={16} height={16} alt="" />
        }
      />
    </Space>
  );
};
