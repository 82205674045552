export const formattedCurrency = (
  value: number,
  precision = 2,
  fallback = ''
) => {
  if (!value && value !== 0) return fallback;

  return formatMoney(value, precision);
};

const formatMoney = (num: number, fractionDigits?: number) => {
  return Number(num.toFixed(9)).toLocaleString('en', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

export const displayLongText = (long: string | undefined, length = 15) => {
  if (long && long.length > length) {
    return long.slice(0, length - 5) + '...' + long.slice(-5);
  }
  return long || '';
};

export const formattedAbsCurrency = (value: number, precision = 2) => {
  return formattedCurrency(Math.abs(value), precision, '-');
};
