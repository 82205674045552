import type { DrawerProps, ModalProps } from 'antd';
import { Drawer } from 'antd';
import Modal from './modal/modal';
import type { FC } from 'react';
import { useBreakpoint } from '../../libs/helpers/useBreakpoint';
import { media } from '@candypig-monorepo/types';
import styled from '@emotion/styled';

type Props = {
  bottom?: string;
  fitContent?: boolean;
} & ModalProps &
  DrawerProps;

const StyledFitContentModal = styled(Modal)<Props>(({ theme, fitContent }) => ({
  ...(fitContent && {
    [media.lg]: {
      minWidth: '40%',
      maxWidth: `calc(100vw - ${theme.token.sizeLG * 2}px)`,
    },
    [media.xl]: {
      maxWidth: 1100,
      margin: `0 auto`,
    },
  }),
}));

const ResponsiveModal: FC<Props> = ({
  children,
  fitContent,
  bottom,
  ...props
}) => {
  const { lg } = useBreakpoint();
  return (
    // prevent event propagate to modal: https://github.com/ant-design/ant-design/issues/16004
    <div onClick={(e) => e.stopPropagation()}>
      {lg ? (
        <StyledFitContentModal
          {...(fitContent ? { width: 'fit-content' } : {})}
          {...props}
        >
          {children}
        </StyledFitContentModal>
      ) : (
        <Drawer
          placement="bottom"
          {...props}
          headerStyle={{ display: 'none' }}
          style={{
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
            marginBottom: 80,
          }}
          onClose={props.onCancel}
          contentWrapperStyle={{
            height: 'auto',
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
          }}
          rootStyle={{
            bottom: bottom ? bottom : 0,
          }}
          bodyStyle={{
            minHeight: '30vh',
            maxHeight: '80vh',
          }}
          destroyOnClose
        >
          {children}
        </Drawer>
      )}
    </div>
  );
};

export default ResponsiveModal;
