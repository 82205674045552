import useSWR from 'swr';
import fetcher from '../api/utils/fetcher';
import type { NotificationsResponse } from '@candypig-monorepo/types';
import { SwrConfig } from '../api/utils/swrConfig';

const useNotification = (pageIndex: number) => {
  const url = `/notification?page=${pageIndex}`;

  const { data, error } = useSWR(url, fetcher, SwrConfig.default);

  return {
    notifications: (data || { date: [], meta: {} }) as NotificationsResponse,
    isLoading: !error && !data,
    isError: error,
  };
};

export default useNotification;
