import Image from 'next/image';
import { useThemeMode } from '../../../libs/hooks/useThemeMode';
import { themeMode } from '../../../constant/themes';
type Props = {
  size?: number;
};

const IconTwitter = ({ size = 24 }: Props) => {
  const { selectedThemeMode } = useThemeMode();
  return (
    <Image
      src={`/images/ico_twitter${
        selectedThemeMode === themeMode.light ? '' : '_white'
      }.svg`}
      width={size}
      height={size}
      alt="ico_twitter"
    />
  );
};

export default IconTwitter;
