import type { ThemeColor } from '../typography/useColor';
import { useColor } from '../typography/useColor';

type Props = {
  size?: number;
  color?: ThemeColor;
};

const IconLogout = ({ size = 14, color = 'secondary' }: Props) => {
  const svgColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="log-out">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.83301 2.66671C3.6562 2.66671 3.48663 2.73695 3.3616 2.86197C3.23658 2.98699 3.16634 3.15656 3.16634 3.33337V12.6667C3.16634 12.8435 3.23658 13.0131 3.3616 13.1381C3.48663 13.2631 3.6562 13.3334 3.83301 13.3334H6.49967C6.86786 13.3334 7.16634 13.6319 7.16634 14C7.16634 14.3682 6.86786 14.6667 6.49967 14.6667H3.83301C3.30257 14.6667 2.79387 14.456 2.41879 14.0809C2.04372 13.7058 1.83301 13.1971 1.83301 12.6667V3.33337C1.83301 2.80294 2.04372 2.29423 2.41879 1.91916C2.79387 1.54409 3.30257 1.33337 3.83301 1.33337H6.49967C6.86786 1.33337 7.16634 1.63185 7.16634 2.00004C7.16634 2.36823 6.86786 2.66671 6.49967 2.66671H3.83301Z"
            fill={svgColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6949 4.1953C10.9553 3.93495 11.3774 3.93495 11.6377 4.1953L14.9711 7.52864C15.2314 7.78899 15.2314 8.2111 14.9711 8.47145L11.6377 11.8048C11.3774 12.0651 10.9553 12.0651 10.6949 11.8048C10.4346 11.5444 10.4346 11.1223 10.6949 10.862L13.5569 8.00004L10.6949 5.13811C10.4346 4.87776 10.4346 4.45565 10.6949 4.1953Z"
            fill={svgColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.83301 8.00004C5.83301 7.63185 6.13148 7.33337 6.49967 7.33337H14.4997C14.8679 7.33337 15.1663 7.63185 15.1663 8.00004C15.1663 8.36823 14.8679 8.66671 14.4997 8.66671H6.49967C6.13148 8.66671 5.83301 8.36823 5.83301 8.00004Z"
            fill={svgColor}
          />
        </g>
      </g>
    </svg>
  );
};

export default IconLogout;
